import FullWidthSection from "../../../section/FullWidthSection";

import Image from "../../../Image";
import ambitiousLeadersDesktop from "@images/v2/for-organisations/ambitious-leaders-desktop.png";
import ambitiousLeadersMobile from "@images/v2/for-organisations/ambitious-leaders-mobile.png";
import { Button } from "../../../../../../src/modules/_common/components/Button";
import useTypeForm from "../../../../hooks/useTypeForm";
import useAnonymousDataLayer from "../../../../../../src/_hooks/useAnonymousDataLayer";

const TellUsSection = () => {
	const { event } = useAnonymousDataLayer();
	const freeConsultationForm = useTypeForm("R6iu6OZO");
	return (
		<FullWidthSection
			bgColor=""
			containerClassName="bg-black text-white pt-10 pt-md-15 pb-15 px-10 px-md-20"
			className="tell-us-section"
		>
			<div className="text-gray-white d-flex flex-column flex-md-row">
				<h2 className="text-center text-md-left" style={{ maxWidth: 600 }}>
					Experience tells us, you need career coaching
				</h2>
				<div className="d-flex flex-column flex-md-row text-center align-items-center pl-0 pl-md-15">
					<a
						href="https://drive.google.com/file/d/14a0AydHU3DsbyT6yM9QGkr-6PimJMylZ/view?usp=drive_link"
						className="btn btn-cornered btn-light-green-black mt-8 mt-md-0"
						target="_blank"
					>
						More details
					</a>
					<Button
						className="btn-cornered btn-black-green mt-4 mt-md-0 ml-0 ml-md-5"
						onClick={() => {
							event("Book free consultation", "coaching", "Organisations interaction");
							freeConsultationForm.open();
						}}
					>
						Book a call
					</Button>
				</div>
			</div>
		</FullWidthSection>
	);
};

export default TellUsSection;
