import Icon from "../Icon";

export default function StatsBox({ className, header, subtitle, arrowDirection, bgPercentage }) {
	return (
		<div
			className={`stats-box p-4 position-relative border border-dark d-flex ${
				arrowDirection === "down" ? "align-items-end" : ""
			} ${className}`}
		>
			<div className="stats-bg-fill bg-light-green" style={{ height: `${bgPercentage}%` }}></div>
			<div className="w-100">
				<div className="d-flex justify-content-center align-items-center">
					<p className="mt-4 mb-12 text-center ">{arrowDirection === "up" && <Icon color="white" name="arrow-up" />}</p>
				</div>

				<p className="m-0 pb-3 percentage-header ">{header}</p>
				<p className="font-size-lg m-0">{subtitle}</p>
				<p className="mb-4 mt-12 text-center">
					{arrowDirection === "down" && <Icon color="white" name="arrow-down" />}
				</p>
			</div>
		</div>
	);
}
