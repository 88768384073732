import Section from "../section/Section";
import Image from "../Image";

export default function ReferenceSection({ title, name, jobTitle, mainImage, logo, marginTop = true }) {
	return (
		<Section
			className={`reference-section pt-8 pt-md-18 pb-15 pm-md-20 mb-0 mb-md-4 border-top border-dark ${
				marginTop ? "mt-15 mt-md-20" : ""
			}`}
		>
			<div className="row text-center text-md-left">
				<div className="col-12 col-md-7 p-0">
					<h2 className="pb-1- pb-md-15 pr-0 pr-md-14">{title}</h2>

					<div className="d-flex flex-column flex-md-row align-items-center align-items-md-start">
						<div className="py-10 py-md-0">
							<h3 className="pb-1">{name}</h3>
							<p className="m-0 font-size-lg" style={{ maxWidth: 276 }}>
								{jobTitle}
							</p>
						</div>
						<Image
							src={logo}
							alt="Clicks and mortar"
							className="pl-0 pl-md-13"
							width={153}
							height={49}
							responsive={false}
						/>
					</div>
				</div>
				<div className="col-md-5 d-none d-md-block p-0">
					<Image src={mainImage} alt={name} className="w-100" responsive={false} />
				</div>
			</div>
		</Section>
	);
}
