import FullWidthSection from "../../../section/FullWidthSection";

import Image from "../../../Image";
import ambitiousLeadersDesktop from "@images/v2/for-organisations/ambitious-leaders-desktop.png";
import ambitiousLeadersMobile from "@images/v2/for-organisations/ambitious-leaders-mobile.png";

const AmbitiousLeadersSection = () => {
	return (
		<>
			<FullWidthSection
				bgColor=""
				containerClassName="m-0 p-0 w-100 mw-100"
				className="ambitious-leaders-section border-top border-dark position-relative"
			>
				<div className="container d-flex flex-column flex-sm-row text-center text-sm-left">
					<div className="d-flex justify-content-center pt-sm-20 pb-sm-16 pb-sm-12 pt-sm-18 px-4 px-sm-10">
						<div style={{ maxWidth: 400 }}>
							<p className="m-0 pb-6 pb-sm-10 font-weight-lg text-underline">Why coaching?</p>
							<h2 className="pb-4 pb-sm-10">Ambitious leaders aren't born, they are coached</h2>
							<Image
								src={ambitiousLeadersMobile}
								alt="Ambitious leaders aren't born, they are coached"
								className="d-sm-none pb-6"
							/>
							<p className="m-0 font-size-lg">
								Unlike other employee learning experiences, coaching is personal and accountable. It unlocks people’s
								potential in a personalised and tailored way, boosting confidence and unlocking ambitious mindsets.
							</p>
						</div>
						<div className="d-sm-none border-bottom border-dark pt-12" />
					</div>
				</div>

				<div className="right-image-wrapper-desktop bg-light-green">
					<Image src={ambitiousLeadersDesktop} alt="Ambitious leaders aren't born, they are coached" className="" />
				</div>
			</FullWidthSection>

			<div className="section section-full-width d-none d-sm-block">
				<div className="row m-0 colored-separator">
					<div className="bottom-color-1 bg-dark-purple"></div>
					<div className="bottom-color-2 bg-light-green"></div>
					<div className="bottom-color-3 bg-dark-red"></div>
				</div>
			</div>
		</>
	);
};

export default AmbitiousLeadersSection;
