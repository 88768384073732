import Section from "../section/Section";
import StatsBox from "../v2/StatsBox";

export default function ImpactSection({ showHeader = true }) {
	return (
		<Section className="impact-section pt-0 pt-md-9 pb-20 pb-md-17 px-0">
			<div className="">
				{showHeader && <p className="m-0 pb-6 pb-md-10 font-size-lg text-underline">Coaching’s impact</p>}

				<div className="stats-wrapper d-flex flex-column flex-md-row">
					<StatsBox
						className=""
						header="90%"
						subtitle="Return on spend for organisations with embedded coaching"
						arrowDirection="up"
						bgPercentage={80}
					/>
					<StatsBox
						className=""
						header="26-76%"
						subtitle="Lower turnover in high turnover organisations"
						arrowDirection="down"
						bgPercentage={20}
					/>
					<StatsBox
						className=""
						header="218%"
						subtitle="More income per employee than those without formalised coaching"
						arrowDirection="up"
						bgPercentage={92}
					/>
				</div>
			</div>
		</Section>
	);
}
