import askNimImg from "@images/v2/for-organisations/ask-nim-screen.png";
import Image from "../../../Image";
import FullWidthSection from "../../../section/FullWidthSection";

export default function AskNimSection() {
	return (
		<FullWidthSection
			className=" d-flex flex-column align-items-center text-center text-gray-white"
			containerClassName="ask-nim-section pt-9 pb-14 pt-md-20 pb-md-20"
		>
			<Image src={askNimImg} alt="Ask Nim co-pilot career helper chat bot" className="mt-0 mt-md-5" />
			<h2 className="h1 pl-5 pr-4 pt-11 pt-md-16">Add Nim to your team</h2>
			<p className="m-0 mx-auto pl-5 pr-4 pb-9 pb-md-12 pt-5 pt-md-4 font-size-xl" style={{ maxWidth: 630 }}>
				Quickly integrate Nim Ai with your website or web portal, train it on your own data and customise the chat bot
				with your own branding.
			</p>
			<a
				href="https://nim.staynimble.co.uk/for-organisations"
				className="btn btn-white font-weight-normal"
				target="_blank"
			>
				Learn more
			</a>
		</FullWidthSection>
	);
}
