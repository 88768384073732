import Section from "../../../section/Section";
import StatsBox from "../../../v2/StatsBox";

const boxes = [
	{
		header: "Plug-in digital Career Coaching",
		content:
			"The ideal solution for organisations in need of a modular career development solution that is quick to launch and flexible for teams at different scales.",
		className: "bg-light-green",
	},
	{
		header: "Free flow scheduling for the time poor",
		content:
			"Online coaching involves no commuting time and no stress: book a time when you have the time and flourish in your career.",
		className: "bg-white",
	},
	{
		header: "Tailored to your business goals",
		content:
			"Tailor the programme to suit your business needs and deliver customised development journeys for team members.",
		className: "bg-purple",
	},
	{
		header: "Track your team's progress",
		content:
			"With the power of live data, keep track of your team's progress and development with our industry-leading coaching platform.",
		className: "bg-mid-green",
	},
];

function Box({ header, content, className }) {
	return (
		<div
			className={`d-flex flex-column flex-md-row justify-content-between mb-6 py-8 py-md-15 pl-3 pr-3 pl-md-13 pr-md-20 border border-dark ${className}`}
		>
			<h2 style={{ maxWidth: 433 }}>{header}</h2>
			<p className="m-0 font-size-lg" style={{ maxWidth: 456 }}>
				{content}
			</p>
		</div>
	);
}

export default function HowItWorksSection() {
	return (
		<Section className="how-it-works-section mb-8 mb-md-19 mt-0 pt-13 mt-md-20 pt-md-10">
			<div className="text-center text-md-left">
				<p className="m-0 pb-8 pb-md-11 font-size-lg text-underline ">How it works</p>
				{boxes.map((box, i) => (
					<Box key={i} {...box} />
				))}
			</div>
		</Section>
	);
}
