import useAnonymousDataLayer from "../../../../../../src/_hooks/useAnonymousDataLayer";
import SparkSection from "../home/SparkSection";
import HomeLatestArticles from "../home/HomeLatestArticles";
import OrganisationsHero from "./OrganisationsHero";
import ResilienceSection from "./ResilienceSection";
import ImpactSection from "../../../v2/ImpactSection";
import AmbitiousLeadersSection from "./AmbitiousLeadersSection";
import HowItWorksSection from "./HowItWorksSection";
import ReferenceSection from "../../../v2/ReferenceSection";
import TellUsSection from "./TellUsSection";
import clicksAndMortar from "@images/v2/clicks-and-mortar.png";
import elevateImage from "@images/v2/for-organisations/elevate.png";
import platformHousingLogo from "@images/v2/platform-housing.png";
import platformImage from "@images/v2/for-organisations/platform.png";
import UnleashSection from "./UnleashSection";
import FeaturedIn from "../../../v2/featured-in/FeaturedIn";
import OrganisationPricingSection from "./OrganisationPricingSection";
import AskNimSection from "./AskNimSection";

const ForOrganisationsDocument = () => {
	const { event } = useAnonymousDataLayer();

	return (
		<>
			<OrganisationsHero />
			<FeaturedIn />
			<ResilienceSection />
			<ImpactSection />
			<AmbitiousLeadersSection />
			<HowItWorksSection />

			<SparkSection bgColor="red" behindBoxClass="bg-white" />
			<ReferenceSection
				title="“Stay Nimble isn’t just an investment in your team; it’s an investment in the future of your business.”"
				name="Carey Benn"
				jobTitle="Founder & CEO"
				mainImage={elevateImage}
				logo={clicksAndMortar}
			/>
			<OrganisationPricingSection />
			<AskNimSection />
			<TellUsSection />
			<ReferenceSection
				title="“Providing the opportunity for team members to have quality coaching time with an external provider has been golden.”"
				name="Georgina Bailey-Allen"
				jobTitle="Income Locality Manager - Tenancy Sustainment"
				mainImage={platformImage}
				logo={platformHousingLogo}
			/>

			<HomeLatestArticles title="Resources" />
			<UnleashSection />
		</>
	);
};

export default ForOrganisationsDocument;
