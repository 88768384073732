import Document from "../../components/v2/Document";

import heroVideoposter from "@images/homepage/preview.jpg";
import ForOrganisationsDocument from "../../components/_pages/v2/for-organisations/ForOrganisationsDocument";

const ForOrganisationsPage = () => {
	return (
		<Document
			title="Elevate Your Team with Embedded 121 Career Coaching"
			className="for-organisations-page"
			ogData={{
				title: "Stay Nimble. Career Coaching for everyone. - Stay Nimble",
				description:
					"Maximise your teams potential. Stay Nimble's expert coaching helps your employees upskill, set goals and advance their careers while boosting productivity.",
				image: heroVideoposter,
				url: "/for-organisations/",
			}}
		>
			<ForOrganisationsDocument />
		</Document>
	);
};

export default ForOrganisationsPage;
