import Section from "../../../section/Section";
import Image from "../../../Image";
import useTypeForm from "../../../../hooks/useTypeForm";
import { Button } from "../../../../../../src/modules/_common/components/Button";
import accenture from "@images/v2/for-organisations/logos/accenture.svg";
import clicks from "@images/v2/for-organisations/logos/clicks.svg";
import esc from "@images/v2/for-organisations/logos/esc.svg";
import kingston from "@images/v2/for-organisations/logos/kingston.svg";
import openUniversity from "@images/v2/for-organisations/logos/open-university.svg";
import platform from "@images/v2/for-organisations/logos/platform.svg";
import useAnonymousDataLayer from "../../../../../../src/_hooks/useAnonymousDataLayer";

export default function UnleashSection({ title, name, jobTitle, mainImage, logo }) {
	const freeConsultationForm = useTypeForm("R6iu6OZO");
	const { event } = useAnonymousDataLayer();
	return (
		<Section className="unleash-section mb-5 mb-9 pt-8 pb-11 pt-md-19 pb-md-15 d-flex flex-column align-items-center bg-light-green border border-dark text-center">
			<h2 className="pb-6" style={{ maxWidth: 498 }}>
				Unleash your team's potential
			</h2>
			<p className="m-0 pb-7 pb-md-10 font-size-lg" style={{ maxWidth: 517 }}>
				Speak to an expert about how we plug-in to your organisation and how we can support and accelerate the
				development of your future leaders.
			</p>
			<Button
				className="btn-cornered btn-white "
				onClick={() => {
					event("Book free consultation", "coaching", "Organisations interaction");
					freeConsultationForm.open();
				}}
			>
				Book a free consultation
			</Button>
			<div className="reference-logos pt-9 pt-md-15 d-flex justify-content-center align-items-center flex-wrap">
				<Image src={accenture} alt="Accenture" className="" width={95} height={24} responsive={false} />
				<Image src={platform} alt="Platform Housing" className="" width={84} height={24} responsive={false} />
				<Image src={clicks} alt="Clicks and Mortar" className="" width={96} height={30} responsive={false} />
				<Image src={kingston} alt="Kingston upon thames" className="" width={38} height={47} responsive={false} />
				<Image src={openUniversity} alt="Open University" className="" width={75} height={31} responsive={false} />
				<Image src={esc} alt="ESC" className="" width={50} height={31} responsive={false} />
			</div>
		</Section>
	);
}
