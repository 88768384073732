import Section from "../../../section/Section";
import useBlogPosts from "../../../../hooks/useBlogPosts";
import { truncateText } from "../../../../../../src/_helpers/_utilities";
import { useMemo } from "react";
import Article from "../../../v2/Article";
import ButtonWithArrow from "../../../v2/ButtonWithArrow";

const HomeLatestArticles = ({ title = "Latest Articles" }) => {
	const articles = useBlogPosts();
	const filteredArticles = useMemo(() => {
		return articles.slice(0, 3);
	}, [articles]);

	return (
		<Section className="home-latest-articles mt-10 mb-8 mt-md-20 mb-md-14 pb-7 pt-5 pb-md-9 pt-md-12 border-top border-bottom border-dark">
			<h2 className="text-center mx-auto pb-6 pb-md-9">{title}</h2>
			<div className="row">
				{filteredArticles.map((article) => {
					return <Article key={article.slug} article={article} />;
				})}
			</div>
			<div className="d-flex justify-content-center">
				<ButtonWithArrow href="/blog" text="Read more" />
			</div>
		</Section>
	);
};

export default HomeLatestArticles;
