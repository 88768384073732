import Section from "../../../section/Section";

export default function ResilienceSection() {
	return (
		<Section className="affordable-section pt-10 pt-md-15 pb-10 pb-md-20 border-bottom border-dark">
			<div className="d-flex flex-column text-center align-items-center">
				<p className="m-0 pb-5 font-size-lg text-underline">The future of work</p>
				<h2 className="pb-4 pb-md-6" style={{ maxWidth: 498 }}>
					Resilience woven into the fabric of your organisation
				</h2>

				<div className="bg-light-green py-8 px-4 py-md-9 px-md-12 border border-dark" style={{ maxWidth: 600 }}>
					<p className="font-size-lg m-0 pb-4">
						Our world has, and is, utterly transformed. The scope and scale of possibility is now measured in
						exponential terms.
					</p>
					<p className="font-size-lg m-0 pb-4">
						So where does that leave you and your team? This disconnect between where industry is headed and where
						people are, is why Stay Nimble exists.
					</p>
					<p className="font-size-lg m-0">
						We’re helping teams and managers in a state of change, who are unable to access or provide the career
						support and development they need to retain, support and develop their future leaders.
					</p>
				</div>
			</div>
		</Section>
	);
}
