import Hero from "../../../v2/hero/Hero";
import heroImage from "@images/v2/for-organisations/hero-2.jpg";

import useAnonymousDataLayer from "../../../../../../src/_hooks/useAnonymousDataLayer";
import { Button } from "../../../../../../src/modules/_common/components/Button";
import useTypeForm from "../../../../hooks/useTypeForm";

const OrganisationsHero = () => {
	const { event } = useAnonymousDataLayer();
	const freeConsultationForm = useTypeForm("R6iu6OZO");

	return (
		<Hero
			upperHeader="Invest in your team"
			title="Plug-in career coaching for your team"
			subtitle="Awaken ambitions with a transformative coaching plug-in fuelling your team's personal and professional growth at scale."
			heroImage={heroImage}
		>
			<div className="d-flex flex-column flex-md-row">
				<Button
					onClick={() => {
						event("Book free consultation", "coaching", "Organisations interaction");
						freeConsultationForm.open();
					}}
					className="btn-cornered btn-white btn-hover-light-green"
				>
					Book a free consultation
				</Button>
			</div>
		</Hero>
	);
};
export default OrganisationsHero;
