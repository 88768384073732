import FullWidthSection from "../../../section/FullWidthSection";
import { useState } from "react";
import useAnonymousDataLayer from "../../../../../../src/_hooks/useAnonymousDataLayer";
import useTypeForm from "../../../../hooks/useTypeForm";
import SVGImage from "../../../SVGImage";
import CheckedBoxSVG from "@svgs/checked-box.svg";
import AskNimSVG from "@images/ask-nim.svg";
import Image from "../../../Image";

const pricingData = [
	{
		title: "Enterprise >250 employees",
		typeformId: "Bm5v2PzK",
		monthlyPrice: "1,200",
		yearlyPrice: "12,000",
		section1: {
			description:
				"The ideal solution for organisations in need of a modular career development solution that is scalable and quick to launch.",
			items: ["Minimum 12 Month contract", "Cancel Anytime"],
		},
		section2: [
			"Lifetime Access",
			"Unlimited Users",
			"-nim- Ai Career Co-Pilot Access",
			"Premium Career Development Hub",
			"Learning Hub",
			"My Skills Library",
			"My Skills Editor",
			"Live Aggregated Reporting to track progress and activity",
			"New content production and application maintenance",
		],
		section3: {
			price: "50",
		},
		section4: {
			price: "25",
		},
		section5: {
			price: "150",
		},
	},
	{
		title: "SME’s <250 employees",
		typeformId: "Bm5v2PzK",
		monthlyPrice: "150",
		yearlyPrice: "1,440",
		section1: {
			description:
				"The ideal solution for smaller teams in need of a low friction plug-in career development solution that is quick to launch and can be tailored easily.",
			items: ["Minimum 12 Month contract", "Cancel Anytime"],
		},
		section2: [
			"Lifetime Access",
			"Unlimited Users",
			"-nim- Ai Career Co-Pilot Access",
			"Premium Career Development Hub",
			"Learning Hub",
			"My Skills Library",
			"My Skills Editor",
			"Live Aggregated Reporting to track progress and activity",
			"New content production and application maintenance",
		],
		section3: {
			price: "70",
		},
		section4: {
			price: "25",
		},
		section5: {
			price: "150",
		},
	},
];

const OrganisationPricingSection = () => {
	const [isYearly, setIsYearly] = useState(false);
	return (
		<FullWidthSection
			bgColor="purple"
			className="pt-12 pt-md-20 d-flex justify-content-center align-items-center font-weight-medium"
			containerClassName="organisation-pricing-section row flex-column"
		>
			<div className="d-flex align-items-center flex-column text-center mx-auto">
				<p className="p-0 m-0 pb-4 text-underline font-size-lg">Plans for Organisations</p>
				<h2 className="h1 pb-5 pb-md-9 font-weight-normal" style={{ maxWidth: 498 }}>
					Platform Access & Integration
				</h2>
				<p className="p-0 m-0 pb-13 pb-md-16 font-size-xl font-weight-normal" style={{ maxWidth: 630 }}>
					Plug-in career development solution that is quick to launch and flexible for teams at different scales. Access
					best-in-class tools and learning content curated by our highly qualified coaches.
				</p>
			</div>
			<div className="d-flex justify-content-center align-items-center pb-2 pb-md-4 toggle-wrapper font-size-lg">
				<span className={`${isYearly ? "" : "text-underline"}`}>Pay Monthly</span>
				<label className="switch mx-5 mx-md-6">
					<input
						type="checkbox"
						checked={isYearly}
						onChange={(e) => {
							setIsYearly(e.target.checked);
						}}
					/>
					<span className="slider round"></span>
				</label>
				<span className={`${isYearly ? "text-underline" : ""}`}>Pay Annually</span>
			</div>
			<div className="row justify-content-center">
				{pricingData.map((data, index) => (
					<PriceDetails key={index} {...data} isYearly={isYearly} />
				))}
			</div>
		</FullWidthSection>
	);
};

function PriceDetails({
	title,
	monthlyPrice,
	yearlyPrice,
	section1,
	section2,
	section3,
	section4,
	section5,
	isYearly,
	typeformId,
}) {
	return (
		<div className="col-12 col-lg-6 mt-14 px-0 px-md-6" style={{ maxWidth: 478 }}>
			<div className="pl-4 pt-5 pr-8 pb-9 border border-dark bg-white">
				<div className="font-size-xl font-weight-normal font-family-young pb-7 pb-md-9">{title}</div>
				<div>
					<span style={{ fontSize: 50 }}>{isYearly ? `£${yearlyPrice}` : `£${monthlyPrice}`}</span>
					<span className="font-size-medium">{isYearly ? `/year` : `/month`}</span>
				</div>
				<div className="font-size-xxxs">OR £{isYearly ? `${monthlyPrice} PER MONTH` : `${yearlyPrice} PER YEAR`}</div>
				<div className="font-size-medium pt-6 pb-9">{section1.description}</div>
				<div className="font-size-medium pb-4 pb-md-10">
					{section1.items.map((item) => (
						<div key={item}>- {item}</div>
					))}
				</div>
				<CreateAccount typeformId={typeformId} />
			</div>
			<div className="pl-4 pt-4 pr-4 pb-6 border-right border-left border-bottom border-dark bg-light-green">
				{/*<div className="position-relative">*/}
				{/*	<div className="check-wrapper">*/}
				{/*		<SVGImage>*/}
				{/*			<CheckedBoxSVG width={43} height={41} />*/}
				{/*		</SVGImage>*/}
				{/*	</div>*/}
				{/*</div>*/}
				<div className="font-size-xxxs">PLATFORM ACCESS</div>
				{section2.map((item) => (
					<div className="font-size-medium pt-3" key={item}>
						{item.indexOf("-nim-") >= 0 ? (
							<Image
								src={AskNimSVG}
								alt="First step"
								className=""
								responsive={false}
								width={65}
								height={13}
								style={{ paddingTop: 4 }}
							/>
						) : null}
						{item.replace("-nim-", "")}
					</div>
				))}
			</div>
			<div className="mt-7 mt-md-2 pl-4 pt-5 pr-6 pb-7 border border-dark bg-gray">
				<EmptyBox />
				<div className="font-size-xxxs">ADD COACHING</div>
				<div>
					<span style={{ fontSize: 50 }}>£{section3.price}</span>
					<span className="font-size-medium">/person each month*</span>
				</div>
				<div className="font-size-xxxs">*ONLY PAY FOR TEAM MEMBERS WHO ENGAGE IN COACHING EACH MONTH</div>

				<div className="font-size-medium pt-5">Unlimited time each month with a dedicated coach</div>
				<div className="font-size-medium pt-3">Free Flow Scheduling</div>
				<div className="font-size-medium pt-3">Action planning and comprehensive session notes</div>
			</div>
			<div className="pl-4 pt-5 pr-6 pb-7 border-left border-right border-dark bg-white">
				<EmptyBox />
				<div className="font-size-xxxs">STRENGTHS ASSESSMENT</div>
				<div>
					<span style={{ fontSize: 50 }}>£{section4.price}</span>
					<span className="font-size-medium">/person</span>
				</div>
				<div className="font-size-medium pt-3">Top 5 Gallup Clifton Strengths assessment and resources</div>
			</div>
			<div className="pl-4 pt-5 pr-6 pb-7 border border-dark bg-gray">
				<EmptyBox />
				<div className="font-size-xxxs">TEAM WEBINAR SESSIONS</div>
				<div>
					<span style={{ fontSize: 50 }}>£{section5.price}</span>
					<span className="font-size-medium">/session</span>
				</div>
				<div className="font-size-medium pt-3">Live Group Session Webinars</div>
			</div>
			<div className="pl-4 pt-5 pr-6 pb-5 border-left border-right border-bottom border-dark bg-white">
				<EmptyBox />
				<div className="font-size-xxxs">BESPOKE</div>
				<div className="font-size-medium pt-3">For Companies who require a bespoke</div>
				<div className="font-size-medium pt-3 pb-4">Leadership or Management programme.</div>
				<CreateAccount typeformId={typeformId} />
			</div>
		</div>
	);
}

function EmptyBox() {
	return null;
	// return (
	// 	<div className="position-relative">
	// 		<div className="check-wrapper">
	// 			<div className="border border-dark bg-white" style={{ width: 32, height: 32 }}></div>
	// 		</div>
	// 	</div>
	// );
}

function CreateAccount({ typeformId }) {
	const { event } = useAnonymousDataLayer();
	const form = useTypeForm(typeformId);
	return (
		<button
			onClick={() => {
				event("Create an account for your team", "account", "Organisations interaction");
				form.open();
			}}
			className="py-2 bg-white btn-hover-light-green border border-dark text-no-underline font-size-lg d-flex w-100 justify-content-center text-center cursor-pointer"
		>
			Create an account for your team
		</button>
	);
}

export default OrganisationPricingSection;
